<template>
  <el-dialog
    title="聊天"
    :visible.sync="userMessage"
    width="60%"
    @close="close"
  >
    <div v-if="messageList.length" style="display:flex;align-items:center;min-height:500px">
      <div class="conversation">
        <div v-for="(item, index) in messageList" :key="index" class="conver-list" @click="getNewsList(item.id, index)">
          <div class="head-photo">
            <img :src="item.otherCustomer.headUrl">
            <div class="number">{{ item.unreadNum }}</div>
          </div>
          <div>
            <div class="conver-title">{{ item.otherCustomer.nickname }}</div>
            <div class="new">{{ item.message.content }}</div>
          </div>
        </div>
      </div>
      <div class="newsbox">
        <div class="news">
          <div class="news-title">{{ newName }}</div>
          <div v-for="(item, index) in newsList" :key="index" class="new-list">
            <div v-if="!item.sender" class="friend">
              <img :src="item.headUrl">
              <div class="main">
                {{ item.content }}
                <div class="icon" />
              </div>
            </div>
            <div v-else class="my">
              <div class="main">
                {{ item.content }}
                <div class="icon" />
              </div>
              <img :src="item.headUrl">
            </div>
          </div>
        </div>
        <div style="margin-top: 15px;">
          <el-input v-model="content" placeholder="请输入内容" style="width:80%" />
          <el-button type="info" style="margin-left: 10px;" @click="send">发送</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getUserMessageList, getNewsList, sendContent } from '@/api/user'
export default {
  props: {
    messageId: {
      type: String || Number,
      default: () => '' || 0
    }
  },
  data() {
    return {
      userMessage: false,
      dialogId: '',
      messageList: [],
      newsList: [],
      newName: '',
      content: '',
      timer: 0,
      newTimer: 0
    }
  },
  watch: {
    messageId() {
      this.getUserMessageList()
    }
  },
  mounted() {
    this.getUserMessageList()
  },
  beforeDestroy() {
    clearInterval(this.timer)
    clearInterval(this.newTimer)
  },
  methods: {
    getUserMessageList() {
      clearInterval(this.timer)
      this.newsList = []
      this.newName = ''
      getUserMessageList({ page: 1, pageSize: 999, recverId: this.messageId }).then(res => {
        this.messageList = res.data.dialogList
        this.newName = res.data.dialogList.length ? res.data.dialogList[0].otherCustomer.nickname : ''
        // 当前会话id默认为第一个
        this.dialogId = res.data.dialogList.length ? res.data.dialogList[0].id : ''
        if (res.data.dialogList.length) {
          getNewsList({ page: 1, pageSize: 999, recverId: this.messageId, dialogId: res.data.dialogList[0].id }).then(res => {
            this.newsList = res.data.list
          })
        }
      })
      if (this.userMessage) {
        this.timer = setInterval(() => {
          getUserMessageList({ page: 1, pageSize: 999, recverId: this.messageId }).then(res => {
            this.messageList = res.data.dialogList
          })
        }, 1000)
      } else {
        clearInterval(this.timer)
      }
    },
    getNewsList(id, index) {
      clearInterval(this.newTimer)
      // 当前的会话ID
      this.dialogId = id
      if (this.userMessage) {
        this.newTimer = setInterval(() => {
          getNewsList({ page: 1, pageSize: 999, recverId: this.messageId, dialogId: id }).then(res => {
            this.newsList = res.data.list
            this.messageList.length ? this.newName = this.messageList[index].otherCustomer.nickname : ''
          })
        }, 1000)
      } else {
        clearInterval(this.newTimer)
      }
    },
    send() {
      if (!this.content) {
        this.$message.error('请输入会话内容')
        return
      }
      sendContent({ content: this.content, dialogId: this.dialogId, senderId: this.messageId }).then(() => {
        this.$message.success('发送成功')
        this.content = ''
      })
    },
    show() {
      this.userMessage = true
    },
    close() {
      clearInterval(this.timer)
      clearInterval(this.newTimer)
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-input-group__append {
    cursor: pointer;
  }
  .conversation::-webkit-scrollbar{width:0;}
  .conversation {
    width: 25%;
    height: 500px;
    overflow: hidden;
    overflow-y: auto;
    .conver-list{
      width: 300px;
      height: 80px;
      background: #c9c6c6;
      display: flex;
      align-items: center;
      padding: 10px;
      .head-photo {
        width: 50px;
        height: 50px;
        border-radius: 4px;
        margin-right: 20px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
        .number {
          width: 20px;
          height: 20px;
          line-height: 20px;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 999;
          border-radius: 50%;
          background-color: #ff403f;
          font-size: 12px;
          text-align: center;
          color: #fff;
        }
      }
    }
    .conver-title {
      font-size: 18px;
      color: #000;
    }
  }
  .conver-list:hover {
    background: #dfdddb;
  }
  .newsbox {
    width: 75%;
    height: 500px;
    padding: 20px;
    background: #f5f5f5;
  }
  .news::-webkit-scrollbar{width:0;}
  .news {
    height: 400px;
    overflow: hidden;
    overflow-y: auto;
    padding: 20px;
    background: #f5f5f5;
    border-bottom: 1px solid #FFF;
    .news-title {
      font-size: 20px;
    }
    .new-list {
      margin-top: 30px;
      .friend {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        .main{
          position: relative;
          height: 40px;
          line-height: 22px;
          background: #FFFFFF;
          border-radius: 8px;
          font-size: 16px;
          font-weight: 500;
          color: #000000;
          padding: 10px;
          margin-left: 20px;
          .icon{
            width: 0;
            height: 0;
            border: 6px solid transparent;
            border-right: 6px solid #FFF;
            position: absolute;
            left: -12px;
            top: 14px;
          }
        }
      }
      .my {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        justify-content: flex-end;
        .main{
          position: relative;
          height: 40px;
          line-height: 22px;
          background: #FFFFFF;
          border-radius: 8px;
          font-size: 16px;
          font-weight: 500;
          color: #000000;
          padding: 10px;
          .icon{
            width: 0;
            height: 0;
            border: 6px solid transparent;
            border-left: 6px solid #FFF;
            position: absolute;
            right: -12px;
            top: 14px;
          }
        }
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-left: 20px;
        }
      }
    }
  }
</style>
