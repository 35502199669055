<template>
  <div>
    <div class="filter-container">
      <el-form inline>
        <el-collapse>
          <el-collapse-item>
            <template slot="title">
              <el-form-item>
                <div @click.stop><el-input v-model="query.nickname" placeholder="请输入用户昵称" clearable /></div>
              </el-form-item>
              <el-form-item>
                <div @click.stop><el-input v-model="query.cellphone" placeholder="请输入手机号" clearable /></div>
              </el-form-item>
              <el-form-item>
                <div @click.stop><el-input v-model="query.city" placeholder="请输入办公地址" clearable /></div>
              </el-form-item>
              <el-form-item>
                <el-select v-model="query.authIdcardStatus" placeholder="请选择实名状态">
                  <el-option
                    v-for="(item, index) in authIdcardStatus"
                    :key="index"
                    :label="item"
                    :value="index"
                  />
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select v-model="query.badgeId" placeholder="请选择佩戴的徽章">
                  <el-option
                    v-for="item in badgeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click.stop="search">查询</el-button>
                <el-button type="danger" icon="el-icon-refresh" @click.stop="refresh">重置</el-button>
                <el-button size="medium" type="primary" icon="el-icon-plus" @click.stop="add">新增</el-button>
              </el-form-item>
            </template>
            <el-form-item style="margin-top: 15px;">
              <el-input v-model="query.fortuneValue1" placeholder="请输入开始总财运值" clearable @blur="fortune" />
              <span style="margin: 0 10px;">~</span>
              <el-input v-model="query.fortuneValue2" placeholder="请输入结束总财运值" clearable @blur="fortune" />
            </el-form-item>
          </el-collapse-item>
        </el-collapse>
      </el-form>
    </div>
    <el-table :data="list">
      <el-table-column
        prop="uid"
        label="UID"
        align="center"
        show-overflow-tooltip
        width="200px"
      />
      <el-table-column
        prop="nickname"
        label="用户昵称"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="cellphone"
        label="手机号"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="headUrl"
        label="头像"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          <el-image :src="row.headUrl" style="width: 50px;height: 50px;border-radius:50%;" :preview-src-list="[row.headUrl]" />
        </template>
      </el-table-column>
      <el-table-column
        prop="authIdcardStatus"
        label="实名认证"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">
          {{ authIdcardStatus[row.authIdcardStatus] }}
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="注册时间"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="recharge"
        label="充值金额"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="fortuneValue"
        label="总财运值"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="currentFortuneValue"
        label="财运值"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="inviterName"
        label="所属高能经纪人"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="invitees"
        label="邀请用户数"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="customerLevel"
        label="高能经纪人"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">{{ customerLevel[row.customerLevel] }}</template>
      </el-table-column>
      <el-table-column
        prop="directInvitees"
        label="直邀会员数"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column
        prop="status"
        label="状态"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="{ row }">{{ status[row.status] }}</template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="300"
      >
        <template slot-scope="{ row }">
          <el-row>
            <el-col :span="row.backstage ? 10 : 12">
              <el-button v-if="row.recommend" size="small" type="danger" @click="cancelRecommend(row.id)">取消合作专家</el-button>
              <el-button v-else type="success" size="small" @click="recommend(row.id)">设置合作专家</el-button>
            </el-col>
            <el-col :span="row.backstage ? 7 : 12">
              <el-button v-if="row.backstage === 1" size="small" type="primary" @click="getDetail(row.id,0)">编辑</el-button>
              <el-button v-else type="primary" size="small" @click="getDetail(row.id,1)">详情</el-button>
            </el-col>
            <el-col :span="6">
              <el-button v-if="row.backstage" type="primary" size="small" @click="message(row.id)">聊天</el-button>
            </el-col>
          </el-row>
          <el-row style="margin-top:10px;">
            <el-col :span="12"><el-button type="danger" size="small" @click="setHeight(row.id)">设置所属高能经纪人</el-button></el-col>
            <el-col :span="12"><el-button type="danger" size="small" @click="setCrown(row.id)">设置皇冠徽章</el-button></el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-container">
      <el-pagination
        background
        :total="count"
        :page-sizes="pageSizes"
        :page-size.sync="pageSize"
        :current-page.sync="page"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="refresh"
        @current-change="(val) => {
          page = val
          getList()
        }"
      />
    </div>
    <Form ref="form" :city="city" :origincity="originCity" />
    <!-- 高能经纪人弹框 -->
    <el-dialog
      title="设置所属高能经纪人"
      :visible.sync="heightShow"
      width="600px"
    >
      <el-input v-model="uid" placeholder="请输入所属高能经纪人UID" />
      <el-button type="primary" style="margin: 0 auto;display: block;margin-top: 40px;" @click="setHeightAgent">确定</el-button>
    </el-dialog>
    <!-- 皇冠徽章 -->
    <el-dialog
      title="设置皇冠徽章"
      :visible.sync="badgeShow"
      width="600px"
    >
      <el-cascader
        style="width:100%;"
        clearable
        placeholder="请选择内容"
        :options="badgeList"
        :props="{ label: 'name', value: 'id' }"
        @change="handleChange"
      />
      <el-button type="primary" style="margin: 0 auto;display: block;margin-top: 40px;" @click="setBadge">确定</el-button>
    </el-dialog>
    <!-- 详情 -->
    <el-dialog
      title="详情"
      :visible.sync="userDetail"
      width="80%"
    >
      <el-descriptions class="margin-top" title="基本信息" :column="3" border>
        <el-descriptions-item label="头像">
          <el-image style="width:100px;height:100px;" :src="userInfo.headUrl" :preview-src-list="[userInfo.headUrl]" />
        </el-descriptions-item>
        <el-descriptions-item label="会员名">{{ userInfo.nickname }}</el-descriptions-item>
        <el-descriptions-item label="微信号">{{ userInfo.wechatNum }}</el-descriptions-item>
        <el-descriptions-item label="邮箱">{{ userInfo.email }}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{ userInfo.cellphone }}</el-descriptions-item>
        <el-descriptions-item label="所在地">{{ userInfo.city }}</el-descriptions-item>
        <el-descriptions-item label="户籍地">{{ userInfo.originCity }}</el-descriptions-item>
      </el-descriptions>

      <el-descriptions class="margin-top" title="实名认证" :column="3" border>
        <el-descriptions-item label="姓名">{{ userInfo.authUser.authContentOne }}</el-descriptions-item>
        <el-descriptions-item label="身份证">{{ userInfo.authUser.authContentTwo }}</el-descriptions-item>
        <el-descriptions-item label="证件">
          <el-image v-if="userInfo.authUser.authContentThree" style="width:100px;height:100px;" :src="userInfo.authUser.authContentThree" :preview-src-list="[userInfo.authUser.authContentThree]" />
          <el-image v-if="userInfo.authUser.authContentFour" style="width:100px;height:100px;" :src="userInfo.authUser.authContentFour" :preview-src-list="[userInfo.authUser.authContentFour]" />
        </el-descriptions-item>
      </el-descriptions>

      <el-descriptions class="margin-top" title="工作认证" :column="3" border>
        <el-descriptions-item label="公司名称">{{ userInfo.authWork.authContentOne }}</el-descriptions-item>
        <el-descriptions-item label="职位名称">{{ userInfo.authWork.authContentTwo }}</el-descriptions-item>
        <el-descriptions-item label="证明材料">
          <el-image v-if="userInfo.authWork.authContentThree" style="width:100px;height:100px;" :src="userInfo.authWork.authContentThree" :preview-src-list="[userInfo.authWork.authContentThree]" />
        </el-descriptions-item>
        <el-descriptions-item label="公司LOGO">
          <el-image v-if="userInfo.companyLogo" style="width:100px;height:100px;" :src="userInfo.companyLogo" :preview-src-list="[userInfo.companyLogo]" />
        </el-descriptions-item>
        <el-descriptions-item label="公司地址">{{ userInfo. workAddress }}</el-descriptions-item>
      </el-descriptions>

      <el-descriptions class="margin-top" title="学历认证" :column="3" border>
        <el-descriptions-item label="学校">{{ userInfo.authEdu.authContentOne }}</el-descriptions-item>
        <el-descriptions-item label="学历">{{ userInfo.authEdu.authContentTwo }}</el-descriptions-item>
        <el-descriptions-item label="材料">
          <el-image v-if="userInfo.authEdu.authContentThree" style="width:100px;height:100px;" :src="userInfo.authEdu.authContentThree" :preview-src-list="[userInfo.authEdu.authContentThree]" />
        </el-descriptions-item>
        <el-descriptions-item label="主营业务">{{ userInfo.business }}</el-descriptions-item>
        <el-descriptions-item label="所需资源">{{ userInfo.resource }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
    <!-- 聊天 -->
    <Message ref="message" :message-id="messageId" />
  </div>
</template>

<script>
import list from '@/mixins/list'
import * as api from '@/api/user'
import Form from './form'
import { recommend, noRecommend, setCustomer, getHeightList, getBadgeList, setBadge, getDetail } from '@/api/user'
import Message from './components/Message'

export default {
  components: { Form, Message },
  mixins: [list],
  data() {
    return {
      api,
      authIdcardStatus: ['未认证', '认证申请中', '认证通过', '认证失败'],
      customerLevel: ['未知', '普通用户', '高能经纪人'],
      status: ['未启用', '启用', '拉黑', '冻结', '未拉黑'],
      heightShow: false,
      heightList: [],
      uid: '',
      badgeList: [],
      badgeId: '',
      badgeShow: false,
      // 详情
      userDetail: false,
      // 聊天
      userMessage: false,
      messageId: '',
      userInfo: {
        headUrl: '',
        authUser: {
          authContentThree: '',
          authContentOne: '',
          authContentFour: ''
        },
        authEdu: {
          authContentThree: ''
        },
        authWork: {
          authContentThree: ''
        }
      },
      city: [],
      originCity: []
    }
  },
  created() {
    this.getHeightList()
    this.getBadgeList()
  },
  methods: {
    fortune() {
      if (Number(this.query.fortuneValue2) < Number(this.query.fortuneValue1)) {
        const t = this.query.fortuneValue2
        this.query.fortuneValue2 = this.query.fortuneValue1
        this.query.fortuneValue1 = t
      }
    },
    // 设置推荐
    recommend(id) {
      recommend(id).then(() => {
        this.$message({
          message: '设置推荐成功',
          type: 'success'
        })
        this.getList()
      })
    },
    // 取消推荐
    cancelRecommend(id) {
      noRecommend(id).then(() => {
        this.$message({
          message: '取消推荐成功',
          type: 'success'
        })
        this.getList()
      })
    },
    setHeight(id) {
      this.heightShow = true
      this.currentId = id
    },
    // 设置高能经纪人
    setHeightAgent() {
      setCustomer({ id: this.currentId, uid: this.uid }).then(() => {
        this.$message({
          message: '设置成功',
          type: 'success'
        })
        this.getList()
        this.heightShow = false
      })
    },
    // 获取高能经纪人列表
    getHeightList() {
      getHeightList({ page: 1, pageSize: 999 }).then(res => {
        this.heightList = res.data.list
      })
    },
    // 获取徽章列表
    getBadgeList() {
      getBadgeList({ page: 1, pageSize: 999 }).then(res => {
        this.badgeList = res.data.list
      })
    },
    handleChange(value) {
      this.badgeId = value[0]
    },
    setCrown(id) {
      this.badgeShow = true
      this.currentId = id
    },
    // 设置徽章
    setBadge() {
      setBadge({ id: this.currentId, badgeId: this.badgeId }).then(() => {
        this.$message({
          message: '设置成功',
          type: 'success'
        })
        this.badgeShow = false
      })
    },
    // 获取详情
    getDetail(id, num) {
      if (!num) {
        this.edit(id)
      } else {
        this.userDetail = true
      }
      getDetail(id).then(res => {
        this.userInfo = res.data
        this.city = [res.data.city1, res.data.city2]
        this.originCity = [res.data.originCity1, res.data.originCity2]
      })
    },
    message(id) {
      this.messageId = id
      this.$refs.message.show()
    }
  }
}
</script>
<style lang="scss" scoped>
  .el-form-item {
    margin-bottom: 0;
  }
  ::v-deep {
    .el-collapse {
      border: 0;
      .el-collapse-item__header {
        border: 0;
      }
    }
    .el-form-item__content {
      display: flex;
      align-items: center;
    }
  }
</style>
