import { ApiUpload } from '@/api/utils'
export default {
  data() {
    return {
      id: '',
      form: {},
      api: {},
      detailShow: false,
      detailInfo: {}
    }
  },
  watch: {
    show() {
      if (!this.show) {
        this.id = ''
      }
    }
  },
  methods: {
    back() {
      this.show = false
    },
    open({ type, id }) {
      this.form = this.$options.data().form
      if (type === 'edit') {
        this.id = id
        this.api.getDetail(id)
          .then(res => {
            this.form = res.data
          })
      }
      this.show = true
    },
    detail(id) {
      this.api.getDetail({ id })
        .then(res => {
          this.detailInfo = res.data
        })
      this.detailShow = true
    },
    upload(file) {
      return ApiUpload(file)
    },
    submit() {
      if (this.id) {
        this.api.edit(this.form)
          .then(() => {
            this.$message.success('修改成功')
            this.$parent.refresh()
            this.back()
          })
      } else {
        this.api.add(this.form)
          .then(() => {
            this.$message.success('新增成功')
            this.$parent.refresh()
            this.back()
          })
      }
    },
    cancel() {
      this.show = false
    }
  }
}
