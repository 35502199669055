<template>
  <el-dialog
    :title="`${id ? '编辑' : '新增'}用户`"
    :visible.sync="show"
    width="80%"
  >
    <el-form v-if="show" :model="form" label-width="auto">
      <el-row :gutter="20">
        <el-col :span="4">
          <el-form-item label="头像">
            <el-upload
              style="width:100px;height:100px;"
              action="#"
              :http-request="uploadImage"
              :show-file-list="false"
              :limit="1"
            >
              <el-image v-if="form.headUrl" style="width:100px;height:100px;" :src="form.headUrl" />
              <div v-else class="icon">
                <i style="width:100px;height:100px;" class="el-icon-plus uploader-icon" />
              </div>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="会员名">
            <el-input v-model="form.nickname" placeholder="请输入内容" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="微信号">
            <el-input v-model="form.wechatNum" placeholder="请输入内容" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="手机号">
            <el-input v-model="form.cellphone" placeholder="请输入内容" />
          </el-form-item>
        </el-col>
        <!-- <el-col :span="8">
          <el-form-item label="性别">
            <el-select v-model="form.gender" placeholder="请选择性别" clearable style="width:100%;">
              <el-option v-for="(value, key) in genders" :key="key" :value="key" :label="value" />
            </el-select>
          </el-form-item>
        </el-col> -->
        <el-col :span="8">
          <el-form-item label="邮箱">
            <el-input v-model="form.email" placeholder="请输入内容" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="所在地">
            <el-cascader
              v-model="city"
              style="width:100%;"
              clearable
              placeholder="请选择内容"
              :options="cityList"
              :props="{ label: 'areaName', value: 'id' }"
              @change="handleChange"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="户籍所在地">
            <el-cascader
              v-model="origincity"
              style="width:100%;"
              clearable
              placeholder="请选择内容"
              :options="cityList"
              :props="{ label: 'areaName', value: 'id' }"
              @change="originCityHandleChange"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="font-size:20px;margin-top:50px;">
        <el-col :span="8">实名认证</el-col>
        <el-col :span="8">工作认证</el-col>
        <el-col :span="8">学历认证</el-col>
      </el-row>
      <el-row :gutter="20" style="font-size:20px;margin-top:30px;">
        <el-col :span="8">
          <el-form-item label="姓名">
            <el-input v-model="form.authUser.authContentOne" placeholder="请输入内容" />
          </el-form-item>
          <el-form-item label="身份证">
            <el-input v-model="form.authUser.authContentTwo" placeholder="请输入内容" />
          </el-form-item>
          <el-form-item label="证件(前)">
            <el-upload
              style="width:100px;height:100px;"
              action="#"
              :http-request="identUploadImage"
              :show-file-list="false"
              :limit="1"
            >
              <el-image v-if="form.authUser.authContentThree" style="width:100px;height:100px;" :src="form.authUser.authContentThree" />
              <div v-else class="icon">
                <i style="width:100px;height:100px;" class="el-icon-plus uploader-icon" />
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="证件(后)">
            <el-upload
              style="width:100px;height:100px;"
              action="#"
              :http-request="identuploadImageAfter"
              :show-file-list="false"
              :limit="1"
            >
              <el-image v-if="form.authUser.authContentFour" style="width:100px;height:100px;" :src="form.authUser.authContentFour" />
              <div v-else class="icon">
                <i style="width:100px;height:100px;" class="el-icon-plus uploader-icon" />
              </div>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="公司">
            <el-input v-model="form.authWork.authContentOne" placeholder="请输入内容" />
          </el-form-item>
          <el-form-item label="职位">
            <el-input v-model="form.authWork.authContentTwo" placeholder="请输入内容" />
          </el-form-item>
          <el-form-item label="证明材料">
            <el-upload
              style="width:100px;height:100px;"
              action="#"
              :http-request="materialUploadImage"
              :show-file-list="false"
              :limit="1"
            >
              <el-image v-if="form.authWork.authContentThree" style="width:100px;height:100px;" :src="form.authWork.authContentThree" />
              <div v-else class="icon">
                <i style="width:100px;height:100px;" class="el-icon-plus uploader-icon" />
              </div>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="学校">
            <el-input v-model="form.authEdu.authContentOne" placeholder="请输入内容" />
          </el-form-item>
          <el-form-item label="学历">
            <el-input v-model="form.authEdu.authContentTwo" placeholder="请输入内容" />
          </el-form-item>
          <el-form-item label="毕业证书">
            <el-upload
              style="width:100px;height:100px;"
              action="#"
              :http-request="diplomaUploadImage"
              :show-file-list="false"
              :limit="1"
            >
              <el-image v-if="form.authEdu.authContentThree" style="width:100px;height:100px;" :src="form.authEdu.authContentThree" />
              <div v-else class="icon">
                <i style="width:100px;height:100px;" class="el-icon-plus uploader-icon" />
              </div>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row style="margin-top:50px;">
        <el-col :span="12">
          <el-form-item label="主营业务">
            <el-input v-model="form.business" type="textarea" placeholder="请输入内容" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所需资源">
            <el-input v-model="form.resource" type="textarea" placeholder="请输入内容" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row type="flex" justify="end">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="onSubmit">确定</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import form from '@/mixins/form'
import * as api from '@/api/user'
import { getCityList } from '@/api/user'

export default {
  mixins: [form],
  props: {
    city: {
      type: Array,
      default: () => []
    },
    origincity: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      api,
      genders: {
        1: '男',
        2: '女'
      },
      cityList: [],
      show: false,
      form: {
        headUrl: '',
        authUser: {
          authContentThree: '',
          authContentFour: ''
        },
        authEdu: {
          authContentThree: ''
        },
        authWork: {
          authContentThree: ''
        }
      },
      selectAddress: false,
      originCitySelectAddress: false
    }
  },
  created() {
    this.getCityList()
  },
  methods: {
    onSubmit() {
      if (!this.selectAddress) {
        this.form.city = this.form.city2
      }
      if (!this.originCitySelectAddress) {
        this.form.originCity = this.form.originCity2
      }
      if (this.id) {
        this.api.edit(this.form)
          .then(() => {
            this.$message.success('修改成功')
            this.$parent.refresh()
            this.back()
          })
      } else {
        this.api.add(this.form)
          .then(() => {
            this.$message.success('新增成功')
            this.$parent.refresh()
            this.back()
          })
      }
    },
    // 头像
    uploadImage({ file }) {
      this.upload(file).then(res => {
        this.form.headUrl = res.data.fileUrl
      })
    },
    // 身份证前
    identUploadImage({ file }) {
      this.upload(file).then(res => {
        this.form.authUser.authContentThree = res.data.fileUrl
      })
    },
    // 身份证后
    identuploadImageAfter({ file }) {
      this.upload(file).then(res => {
        this.form.authUser.authContentFour = res.data.fileUrl
      })
    },
    // 证明材料
    materialUploadImage({ file }) {
      this.upload(file).then(res => {
        this.form.authWork.authContentThree = res.data.fileUrl
      })
    },
    // 毕业证书
    diplomaUploadImage({ file }) {
      this.upload(file).then(res => {
        this.form.authEdu.authContentThree = res.data.fileUrl
      })
    },
    getCityList() {
      getCityList().then(res => {
        this.cityList = res.data
      })
    },
    handleChange(value) {
      this.form.city = value[1]
      this.selectAddress = true
    },
    originCityHandleChange(value) {
      this.form.originCity = value[1]
      this.originCitySelectAddress = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .uploader-icon::before {
    position: relative;
    top: -35px;
  }
</style>
