import request from '@/utils/axios'

/** 上传文件 */
export function ApiUpload(file) {
  const form = new FormData()
  form.append('file', file)
  return request({
    url: '/admin/upload/picture',
    method: 'post',
    data: form,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}
